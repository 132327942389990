<template>
  <vue-final-modal
    class="modal-global-search"
    :overlay-transition="{ mode: 'in-out', duration: 250 }"
    :content-transition="{ mode: 'in-out', duration: 250 }"
  >
    <div class="modal-global-search__container">
      <atomic-icon class="modal-global-search__close" id="close" @click="closeDialog" />

      <div class="modal-global-search__title">
        {{ getContent(popupsData, defaultLocalePopupsData, 'globalSearch.title') }}
      </div>

      <div class="modal-global-search__header">
        <tab-global-search v-model="currentTab" />

        <form-input-search
          :value="searchValue"
          :hasClearIcon="!!searchValue"
          :placeholder="getContent(popupsData, defaultLocalePopupsData, 'globalSearch.searchPlaceholder')"
          @input="handleSearchInput"
        />
      </div>

      <div class="modal-global-search__body" ref="bodyRef">
        <modal-content-global-search-games
          v-if="currentTab === GlobalSearchTabs.GAMES"
          :searchValue="searchValue"
          @updateSearchValue="updateSearchValue"
        />

        <modal-content-global-search-sports v-else :searchValue="searchValue" @updateSearchValue="updateSearchValue" />
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import debounce from 'lodash/debounce';

  import { scrollElemToTop } from '@skeleton/utils/scrollUtils';

  import { ModalName } from '@skeleton/consts/modals';
  import { GlobalSearchTabs } from '@skeleton/consts/tabs/global-search-tabs';

  const { closeModal } = useModalStore();
  const { getContent } = useProjectMethods();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);
  const globalSearchStore = useGlobalSearchStore();

  const route = useRoute();

  const bodyRef = ref();

  const currentTab = ref(GlobalSearchTabs.GAMES);

  const searchValue = ref('');

  const handleSearchInput = debounce(async value => {
    searchValue.value = value;
  }, 500);

  const updateSearchValue = (value: string) => {
    searchValue.value = value;
  };

  const closeDialog = () => {
    closeModal(ModalName.GLOBAL_SEARCH);
    globalSearchStore.$reset();
  };

  watch(searchValue, () => {
    scrollElemToTop(bodyRef);
  });

  watch(() => route.query, closeDialog);

  watch(isMobile, isMobile => {
    if (isMobile) {
      closeDialog();
    }
  });
</script>

<style src="~/assets/styles/components/modal/global-search.scss" lang="scss" />
